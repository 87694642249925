.cinematic-section {
  position: relative;
  width: 100%;
  height: 85vh;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  gap: 2rem;
  padding: 2rem;
}

.title-wrapper {
  text-align: center;
  width: 100%;
  max-width: 800px;
}

.animated-word {
  color: white;
  font-size: 2.75em !important;
  font-weight: 500 !important;
  will-change: transform, opacity;
  margin: 0;
}

.animated-subtitle {
  text-align: center;
  font-weight: 300 !important;
  max-width: 500px;
  font-size: 1.25em !important;
  will-change: transform, opacity;
  margin: 0;
  margin-top: 1rem;
}

.video-container {
  position: relative;
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-video {
  height: 100%;
  clip-path: inset(0% 20% 0% 20%);
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  will-change: opacity;
}

.mac {
  width: 70%;
  height: auto;
  max-height: 50vh;
  object-fit: contain;
  clip-path: inset(15% 15% 15% 15%);
  scale: 1.85;
}

.iphone {
  transform: scale(1.2);
  height: 100% !important;
  @media (max-width: 1024px) {
    clip-path: inset(0% 30% 0% 30%);
    height: 100% !important;
  }
}
