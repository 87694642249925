/* Ajustes de tamaño y padding del swiper para mejor responsividad */
.swiper.swiper-cards {
  width: 80em !important;
  padding-left: 10em !important;
  padding-right: 10em !important;
  padding-bottom: 5em !important;

  @media (max-width: 768px) {
    width: 100% !important;
    padding-left: 2em !important;
    padding-right: 2em !important;
    padding-bottom: 2em !important;
  }
}

/* Ajuste del tamaño de la tarjeta para hacerlo más responsive */
.benefits-card {
  width: 100% !important;
  height: 45em !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  @media (max-width: 768px) {
    height: 30em !important;
  }
}

.swiper-wrapper {
  width: 100% !important;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  border-radius: 48px;
}

/* Contenedor de la imagen */
div.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
  border-radius: 48px;
  background: linear-gradient(180deg, #f7f7f71a 0%, #f7f7f70f 100%);
}

img {
  object-fit: contain;
  object-position: bottom;
  width: 100%;
  height: 100%;
}

/* Botones de navegación del swiper */
.swiper-button-next,
.swiper-button-prev,
.swiper-button-next::after,
.swiper-button-prev::after {
  color: var(--primary-color) !important;
  font-size: 1.25em !important;
  opacity: 0.9;

  @media (max-width: 768px) {
    font-size: 0em !important;
  }
}

/* Paginación activa del swiper */
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
}

/* Título principal de la sección */
.benefits-title {
  color: #ffffff !important; /* Color sólido para mayor contraste */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); /* Sombra sutil para mejorar legibilidad */
}

/* Botones/links de la parte superior (stakeholders) */
.benefits-buttons p {
  margin: 0;
  line-height: 3rem;
}

.benefits-buttons .MuiTypography-root {
  color: #ffffff !important; /* Color sólido */
  font-weight: 500 !important; /* Peso medio para legibilidad */
  text-transform: lowercase;
  letter-spacing: 0.05em;
}

/* Destacar el elemento activo más */
.benefits-buttons .MuiTypography-root[style*="background: linear-gradient"] {
  font-weight: 500 !important; /* Mayor peso para el activo */
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); /* Sombra para contraste */
}

/* Titular de las tarjetas */
h1.card-header {
  font-size: 3em !important;
  color: #ffffff !important; /* Color sólido */
  font-weight: 500 !important; /* Peso muy alto para resaltar */
  text-shadow: 0 2px 6px rgba(13, 0, 0, 0.166); /* Sombra para mejorar legibilidad */

  @media (max-width: 768px) {
    font-size: 2em !important;
  }
}

.card-container {
  z-index: 10 !important;
}

.card-title {
  font-size: 1.5em !important;
  font-weight: 400 !important; /* Peso medio-alto para resaltar */
  color: #ffffff !important; /* Color sólido */
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); /* Sombra sutil */
  margin-bottom: 1em;

  @media (max-width: 768px) {
    font-size: 1.25em !important;
  }
}

.card-overlay {
  position: absolute;
  z-index: 1 !important;
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0.25) 0%,
    rgba(23, 87, 101, 0.95) 100%
  );
  opacity: 1;
}

/* Descripción del contenido dentro de las tarjetas */
.card-description {
  font-size: 1em !important;
  font-weight: 400 !important; /* Peso regular para legibilidad */
  color: #ffffff !important; /* Color sólido */
  margin-bottom: 0.5em;
  line-height: 1.4;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); /* Sombra sutil */

  @media (max-width: 768px) {
    font-size: 0.9em !important;
  }
}

/* Animación inicial */
.swiper-box,
.benefits-text {
  opacity: 0;
}

/* Eliminamos el degradado en el texto resaltado */
.gradient-text {
  background: linear-gradient(90deg, #25aecd, #00c4cc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

/* Opcional: Puedes mantener alguna clase para futuras modificaciones, pero actualmente no la usamos */
