:root {
  --gradient-bg-size: 400%;
}

.animated-gradient-text {
  background: linear-gradient(
      90deg,
      var(--primary-color),
      var(--secondary-color),
      var(--primary-color)
    )
    0 0 / var(--gradient-bg-size) 100%;
  color: transparent !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

b.gradient-text {
  color: var(--primary-color) !important;
}

.animated-gradient-text b {
  font-weight: 600;
  font-size: 1.15em;
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background-clip: unset;
}

@media (prefers-reduced-motion: no-preference) {
  .animated-gradient-text {
    animation: move-gradient 15s linear infinite;
  }
}

@keyframes move-gradient {
  to {
    background-position: var(--gradient-bg-size) 0;
  }
}

.animated-gradient-text {
  will-change: background-position;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#hero-demo {
  opacity: 1;
}

@media (prefers-color-scheme: dark) {
  .animated-gradient-text {
    background: linear-gradient(
        90deg,
        var(--secondary-color),
        var(--fourth-color),
        var(--secondary-color)
      )
      0 0 / var(--gradient-bg-size) 100%;
  }
}

#hero-subtitle {
  margin-top: 3em;
}

.hero-title-primitive {
  letter-spacing: "-0.02em";
  font-weight: 400 !important;
}

.hero-subtitle-fixed {
  text-align: center;
  width: 100%;
  display: block;
}
