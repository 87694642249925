.wave-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
}

.wave-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

#gradient-canvas {
  width: 100% !important;
  height: 100% !important;
  pointer-events: none !important;

  position:fixed;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: center;
  opacity: 0.4;
  --gradient-color-1: #0b1918;
  --gradient-color-2: #132f4c;
  --gradient-color-3: #335251;
  --gradient-color-4: #132f4c;
}

