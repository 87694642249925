label {
  margin-bottom: 30px !important;
}

.form-col {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 9em;
}

.MuiFormHelperText-root.Mui-error {
  position: absolute;
  top: 3em;
}

.send-contact-btn {
  border-radius: 16px !important;
  color: white !important;
}

.send-contact-btn:hover {
  background-color: var(--secondary-color) !important;
  color: white !important;
  scale: 1.02;
}

.send-contact-icon {
  margin-left: 0.25em;
  margin-right: 0.25em;
  font-size: 1.25em;
}

.send-contact-btn.Mui-disabled{
    opacity: 0.3;
    cursor: not-allowed !important;
}
