/* Provide a real background and remove overflow from the parent so the fades work properly */
.integrations-section {
  position: relative;
  padding: 4rem 0;
  /* No overflow: hidden here */
}

.logos-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

/* Add fade overlay */
.logos-container::before,
.logos-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25%;
  z-index: 2;
  pointer-events: none;
}

.logos-container::before {
  left: 0;
  background: linear-gradient(to right, 
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
  mix-blend-mode: destination-out;
}

.logos-container::after {
  right: 0;
  background: linear-gradient(to left, 
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
  mix-blend-mode: destination-out;
}

.logos-scroll {
  display: flex;
  position: relative;
  width: max-content;
}

.logos-track {
  display: flex;
  animation: scroll 30s linear infinite;
}

.partner-logo-container {
  flex: 0 0 auto;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 0 2rem;
  transition: transform 0.3s ease;
}

.partner-logo-container:hover {
  transform: translateY(-5px);
}

.partner-logo {
  max-width: 150px;
  max-height: 80%;
  object-fit: contain;
  transition: all 0.3s ease;
}

.partner-logo:hover {
  filter: grayscale(0%);
  opacity: 1;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .integrations-section {
    padding: 3rem 0;
  }
  
  .partner-logo-container {
    height: 100px;
    margin: 0 1rem;
  }

  .partner-logo {
    max-width: 120px;
  }

  .logos-container::before,
  .logos-container::after {
    width: 20%;
  }
}
