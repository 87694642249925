body {
  font-family: "Poppins", sans-serif !important;

  overflow-x: hidden;
  max-width: 100%;
  overscroll-behavior-x: none;
  position: relative;
}

body {
  font-size: calc(15px + 0.390625vw);
}

:root {
  --primary-color: #016075;
  --primary-color-50: #01607550;
  --primary-color-10: #01607510;
  --secondary-color: #84c3bf;
  --secondary-color-50: #84c3bf50;
  --secondary-color-10: #84c3bf10;
  --tertiary-color: #03045e;
  --fourth-color: #0096c7;
  --card-border-radius: 18px;
}

* {
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
  max-width: 100%;
  overscroll-behavior-x: none;
}

img,
video,
iframe {
  max-width: 100%;
  height: auto;
}

.theme-switch-btn {
  position: fixed !important;
  bottom: 1em;
  right: 1em;
  scale: 1.2;
  z-index: 20;
}

.vita-logo {
  width: 8em;
  object-fit: contain;
  padding: 1em;
  padding-bottom: 1.2em;
  padding-top: 0.8em;

  @media (max-width: 1000px) {
    width: 6em;
    padding: 0.5em;
  }
}

header {
  margin-top: 20px !important;

  @media (max-width: 1000px) {
    margin-top: 5px !important;
  }
}

.try-vita-btn {
  margin-right: 10px !important;
}

button {
  border-radius: 16px !important;
}

.vita-logo-btn {
  padding: 0 !important;
  animation: none !important;
  z-index: 11 !important;
  width: 1.25em;
  margin-left: 0.25em;
  height: 1.25em;
  object-fit: contain;
  padding: 1em;
  padding-bottom: 1.2em;
  padding-top: 0.8em;
}

#hero-demo {
  object-fit: contain;
  box-shadow: 0 0 12px 8px hsla(220, 25%, 80%, 0.2);
  border-radius: 16px;
  transition: scale 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
}

#hero-demo:hover {
  scale: 1.02;
}

.vita-hero-demo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiChip-filled:active,
.MuiChip-filled:focus,
.MuiChip-filled:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.numbers-title {
  opacity: 0;
}

.responsive-container {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
}

.marvel-device .screen {
  background: #283639 !important;
}

.marvel-device.iphone-x .notch {
  width: 160px !important; /* what changes here */
  left: 133px !important; /* has to change -half here */
}

.marvel-device.iphone-x .camera {
  display: none !important;
}

.marvel-device.iphone-x {
  transform: scale(0.9);
}

.smaller-phone-container {
  transform: scale(0.5);
  transform-origin: center center;
  height: 844px; /* iPhone X height */
  width: 390px; /* iPhone X width */
  margin: 0 auto;
}

.smaller-phone-wrapper {
  height: 422px; /* 50% of original height */
  width: 195px; /* 50% of original width */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}

.faq-accordion {
  background-color: #00000036 !important;
}

.MuiInputBase-root {
  background-color: #00000036 !important;
  background: #00000036 !important;
}
